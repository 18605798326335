import React, { useContext, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Footer from '../components/Footer'
import SEO from "../components/SEO"
import {
  Container,
  Box,
  SimpleGrid,
  FormControl,
  Input,
  Textarea,
  FormLabel,
  Select,
} from '@chakra-ui/react'
import { H1 } from '../theme/Headings'
import { spacing, colors, breakpoints } from '../theme/themeAlopex'
// import BackgroundImage from 'gatsby-background-image'
import ColorContext from '../context/ColorContext'

const ClientIntakePage = ({ data }) => {
  const { color } = useContext(ColorContext)

  // Clear transform matrix from transition wrapper interfering with nested fixed header
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const tlWrapper = document.querySelector('.tl-wrapper-status--entered')
      if (tlWrapper !== null) {
        tlWrapper.style.transform = null
      }
    }
  })

  return (
    <Layout>
      <SEO title='The Alopex Designers & Developers - Alopex' />
      <Box backgroundColor={color.alpha}>
        <Container maxWidth={breakpoints.xl} pt={spacing.header} pb={spacing.section} color={colors.brand.white}>
          <H1 variant="pageHeader" color={colors.brand.white} mb={spacing.groups}>Client Intake Form</H1>
          <form name="ClientIntake" method="POST" data-netlify="true" netlify>
            <SimpleGrid columns={[1, null, 2]} gap="10px" mb="10px" sx={{ 'input[type="text"], select, textarea': { minHeight: '55px', color: color.alpha } }}>
              <FormControl id="name" isRequired>
                <Input type="text" name="name" placeholder="Name" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <FormControl id="companyName">
                <Input type="text" name="Company Name" placeholder="Company Name" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
            </SimpleGrid>
              <Input type="text" name="Company Address" mb="10px" placeholder="Address" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
            <SimpleGrid columns={[1, null, 2]} gap="10px" mb="10px" sx={{ 'input[type="text"], select, textarea': { minHeight: '55px', color: color.alpha } }}>
              <FormControl id="email" isRequired>
                <Input name="Email" type="email" placeholder="Email" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
              <FormControl id="phone">
                <Input name="Phone" type="tel" placeholder="Phone" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
              </FormControl>
            </SimpleGrid>
            <FormControl id="web-state">
              <FormLabel color={colors.brand.lightGray}>Current State of Website</FormLabel>
              <Textarea name="Web State" display="block" mb="10px" placeholder="What is your current website? What current SEO or SEM are you utilizing? Current Design?" borderRadius="0" height="200px" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
            </FormControl>
            <FormControl id="social-media">
              <FormLabel color={colors.brand.lightGray}>Social Media</FormLabel>
              <Textarea name="Social Media" display="block" mb="10px" placeholder="Please provide your social media links." borderRadius="0" height="200px" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
            </FormControl>
            <FormControl id="goals">
              <FormLabel color={colors.brand.lightGray}>Description & Goals</FormLabel>
              <Textarea name="Description and Goals" display="block" mb="10px" placeholder="What value are you looking for Alopex to bring to your service?" borderRadius="0" height="200px" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
            </FormControl>
            <FormControl id="specifics">
              <FormLabel color={colors.brand.lightGray}>Deliverables & Specifications</FormLabel>
              <Textarea name="Deliverables and Specifications" display="block" mb="10px" placeholder="What specific products, servies, and/or results are you looking for Alopex to provide?" borderRadius="0" height="200px" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
            </FormControl>
            <FormControl id="target-audience">
              <FormLabel color={colors.brand.lightGray}>Target Audience & Current Demographic</FormLabel>
              <Textarea name="Target Audience" display="block" mb="10px" placeholder="What is the current target audience and demographic data you are trying to reach? Is this working for reaching your clients? How can we help?" borderRadius="0" height="200px" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
            </FormControl>
            <FormControl id="timeline">
              <FormLabel color={colors.brand.lightGray}>Expected Timeline</FormLabel>
              <Textarea name="Expected Timeline" display="block" mb="10px" placeholder="What is the expected time frame you are expecting for us to deliver?" borderRadius="0" height="200px" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} />
            </FormControl>
            <FormControl id="budget">
              <FormLabel color={colors.brand.lightGray}>Budget (USD)</FormLabel>
              <Select name="Budget[]" borderRadius="0" backgroundColor={colors.brand.lightGray} _placeholder={{color: color.alpha}} color={color.alpha} >
                <option value="" disabled="" selected="">Select option</option>
                <option value="10,000 - 15,000" name="tenToFifteen">10,000 - 15,000</option>
                <option value="15,000 - 30,000" name="fifteenToThrity">15,000 - 30,000</option>
                <option value="30,000 - 50,000" name="thirtyToFifty">30,000 - 50,000</option>
                <option value="50,000 - 100k +" name="fiftyPlus">50,000 - 100k +</option>
              </Select>
            </FormControl>
            <input type="hidden" name="form-name" value="ClientIntake" />
            <Input
              type="submit"
              mt="20px"
              value="Send"
              _hover={{
                color: color.alpha,
              }}
            />
          </form>
        </Container>
      </Box>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query aloIntake {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default ClientIntakePage
